<template>
  <div>
    <div class="tip">提示：数据用于公众号跳转名片小程序</div>
    <div class="contentWrap">
      <div v-if="info.isMkt"><span class="title">防伪码名片小程序id：</span>
        <!-- <span>wx19adaa0665cc0c6a</span> -->
        <el-button type="text" size="mini" class="tag-read" @click="copyLink('wx19adaa0665cc0c6a')"
          :data-clipboard-text="Link">复制链接
        </el-button>
      </div>
      <div v-if="info.isMkc">
        <div><span class="title">防伪码小程序id：</span>
          <!-- <span>wx71e5aa098fae6c5d</span> -->
          <el-button type="text" size="mini" class="tag-read" @click="copyLink('wx71e5aa098fae6c5d')"
            :data-clipboard-text="Link">复制链接
          </el-button>
        </div>
        <div>
          <span class="title">公众号文章引用模版链接：</span><span>请查看标识页面列表</span>
        </div>
      </div>

      <div v-if="info.isHaveMall">
        <div>
          <span class="title">公众号文章引用商城链接：</span>
          <!-- <span>{{ `pages/Products/main?companyId=${companyId}`}}</span> -->
          <el-button type="text" size="mini" class="tag-read"
            @click="copyLink('pages/Products/main?companyId=' + companyId)" :data-clipboard-text="Link">复制链接
          </el-button>
        </div>
        <div>
          <span class="title">公众号文章引用商品链接：</span><span>请查看商品列表</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      companyId: null,
      Link: null,
      info: {}
    }
  },
  created() {
    this.companyId = JSON.parse(localStorage.getItem('info')).companyId
    this.info = JSON.parse(localStorage.getItem('info'));
  },
  methods: {
    copyLink(data) {
      this.Link = data // data.customPageId
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        console.log('复制成功')
        //  释放内存

        // clipboard.destory()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        // clipboard.listener.destory()
      })
    }
  }


}
</script>
<style scoped>
.tip {
  color: #f00;
  line-height: 30px;
}

.contentWrap {
  line-height: 40px;
  font-size: 14px;
  color: #333;
  padding-top: 20px;
}

.contentWrap .title {
  font-weight: bold;
}
</style>
